import React from "react"
import { graphql, Link } from "gatsby"
import { motion } from "framer-motion"
import Loadable from "@loadable/component"
import Img from "gatsby-image"
import ReactMapGL from 'react-map-gl'
import {Helmet} from 'react-helmet'

import Demo from "../components/Demo"
import DemoTable from "../components/DemoTable"
import Layout from "../components/layouts/Layout"
import FeatureTile from "../components/FeatureTile"
import FeatureBlock from "../components/FeatureBlock"
import DemoStyles from "../components/Demo.module.scss"
import Button from "../components/Button"

const variants = {
  hidden: { opacity: 0.2 },
  visible: { opacity: 1 },
}

const header = () => (
  <div className={`bg-gray-100`}>
    <div className="w-full max-w-screen-xl mx-auto px-6 pb-8">
      <div className="flex flex-wrap flex-row">
        <div className="w-full flex">
          <motion.div 
            className="pt-12 text-left"
            initial="hidden"
            animate="visible"
            variants={variants}
            >
            <h1 className="text-4xl text-black" style={{maxWidth: 420+'px', lineHeight: 60+'px', marginBottom: 20}}>CounterSet is a platform for smart packaging.</h1>
            <p className="text-xl text-gray-800 font-normal max-w-md ">
                We built CounterSet to bring smart packaging to manufacturers of all sizes. 
            </p>
          </motion.div>
        </div>
      </div>
      
    </div>
  </div>
)

export default ({}) => {
  return (
    <Layout header={header()}>
      <Helmet>
        <title>About</title>
        <meta name="description" content="CounterSet is a platform for smart packaging built in South Australia." />
        <meta property="og:description" content="CounterSet is a platform for smart packaging built in South Australia." />
      </Helmet>
      <p className="mt-6"></p>

      <div className={`flex sm:flex-row flex-wrap my-12`}>
            <div className="flex items-center">
                <div className={`pt-4`}>
                    <h3 className="font-bold text-2xl mb-2">Our Product</h3>
                    <div className="text-lg pb-4">
                        CounterSet gives you the tools to create innovative packaging and product experiences with no code.<br />
                    </div>
                    <div className="text-lg">
                        Anti-counterfeiting and product protection is now open to all manufacturers, regardless of size.
                        {/* Every product can now have advanced anti-counterfeiting and real-time customer analytics */}
                    </div>
                    <div className="text-lg">
                        
                        {/* Every product can now have advanced anti-counterfeiting and real-time customer analytics */}
                    </div>
                </div>
            </div>
      </div>

      <p className="text-xl text-black font-normal ">
            
      </p>
      <div className={`flex sm:flex-row flex-wrap my-12`}>
            <div className="flex items-center">
                <div className={`pt-4`}>
                    <h3 className="font-bold text-2xl mb-2">Based in Australia</h3>
                    <div className="text-lg pb-4">
                    Our office is located in Adelaide, South Australia.
                    </div>
                    <div className="text-lg">
                    Please address any correspondence to: <p>
                    Jaupana Pty Ltd, PO Box 946, <br />Blackwood, South Australia 5051
                    </p>
                    </div>
                </div>
            </div>
        </div>


  </Layout>
  )
}
